import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { REMOVE_A_DISCLOSER, REMOVE_LATE_REQUEST_DISCLOSER } from '@/graphql/disclosure/mutations';
import { GET_ACTIVE_CYCLE, GET_LIST_FOR_CYCLE, GET_USER_TASKS } from '@/graphql/disclosure/queries';
import {
  GetActiveDisclosureCycleQuery,
  GetDiscloserListForCycleQuery,
  RemoveDiscloserLateRequestFromListMutation,
} from '@/lib/discloser/__generated__/graphql';
import { useStore } from '@/store/authorityType';
import { disclosureClient } from '@/utils/apollo-client';
import { onApolloError } from '@/utils/errorFormat';
import { Routes } from '@/utils/urls';
import { useMutation, useQuery } from '@apollo/client';
import { ListItemButton, ListItemText, Stack } from '@mui/material';

type Props = {
  isProd: boolean;
};
export const ResetButton = ({ isProd }: Props) => {
  const { authorityTypeId } = useStore();
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const [
    removeDiscloserList,
    { loading: isRemovingDiscloserList },
  ] = useMutation(REMOVE_A_DISCLOSER, {
    client: disclosureClient,
    variables: {
      authorityTypeId,
    },
    refetchQueries: [
      {
        query: GET_USER_TASKS,
        variables: {
          authorityTypeId,
        },
      },
    ],
  });

  const { data: activeCycleData, loading: isLoadingCycleData } = useQuery<GetActiveDisclosureCycleQuery>(
    GET_ACTIVE_CYCLE,
    {
      fetchPolicy: 'network-only',
      client: disclosureClient,
      onError: onApolloError,
      variables: { authorityTypeId },
      skip: !authorityTypeId,
    },
  );

  const { data: activeDiscloserListData, loading: loadingActiveDiscloserListData } =
    useQuery<GetDiscloserListForCycleQuery>(GET_LIST_FOR_CYCLE, {
      fetchPolicy: 'network-only',
      client: disclosureClient,
      onError: onApolloError,
      skip: !authorityTypeId || !activeCycleData?.getActiveDisclosureCycle || isLoadingCycleData,
      variables: {
        authorityTypeId: authorityTypeId,
        disclosureCycleId: activeCycleData?.getActiveDisclosureCycle?.disclosureCycleId,
      },
    });

  const [
    removeDiscloserLateRequestFromList,
    { loading: isRemovingLateRequests },
  ] = useMutation<RemoveDiscloserLateRequestFromListMutation>(REMOVE_LATE_REQUEST_DISCLOSER, {
    client: disclosureClient,
    onError: onApolloError,
  });

  const handleReset = async () => {
    const discloserListId = activeDiscloserListData?.getDiscloserListForCycle?.discloserListId;

    try {
      if (discloserListId) {
        await removeDiscloserLateRequestFromList({
          variables: {
            discloserListId: discloserListId,
            organisationIds: null,
            deleteAll: true,
          },
        });
      }

      await removeDiscloserList();

      enqueueSnackbar('Reset complete.', { variant: 'success' });

      router.push(Routes.Home);
    } catch (err) {
      console.error(err);
    }
  };

  const loading =
    isRemovingDiscloserList || isRemovingLateRequests || loadingActiveDiscloserListData || isLoadingCycleData;

  if (isProd) return null;
  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <ListItemButton
        disabled={loading}
        onClick={handleReset}
      >
        <ListItemText primary={loading ? 'Loading' : 'Reset'} />
      </ListItemButton>
    </Stack>
  );
};
